// Import Sass
import './style.scss';

import Typed from 'typed.js';

// Typed JS
const allMainHero = document.querySelectorAll('.wp-block-b-temp-bplugins-hero');
Object.values(allMainHero).map(hero => {
    const heroId = hero.id;

    const currentHero = document.getElementById(heroId);
    const titles = JSON.parse(currentHero.dataset.titles);

    new Typed('.typedElements', {
        strings: titles,
        typeSpeed: 45,
        backSpeed: 45,
        backDelay: 500,
        startDelay: 1000,
        loop: true
    });
});

