const Footer = ({ attributes }) => {
	const { logo, logoLink, logoWidth, aboutText, logos, widgets, nav, copyright } = attributes;

	return <footer className='bpFooter'>
		<div className='container'>
			<div className='footer-top'>
				<div className='row'>
					<div className='col-lg-4 col-md-6 col-sm-12 footer-column'>
						<div className='about-widget footer-widget'>
							{logo && <figure className='footer-logo'><a href={logoLink}>
								<img style={{ width: logoWidth }} src={logo} alt='Logo' /></a>
							</figure>}

							<div className='text'>{aboutText}</div>

							<div className='logos'>
								{logos?.map((item, index) => {
									const { img, link } = item;

									return img ? <a key={index} href={link}>
										<img src={img} alt='Logo' />
									</a> : null;
								})}
							</div>
						</div>
					</div>

					{widgets?.map((item, index) => {
						const { title, content, customClass } = item;

						return <div key={index} className='col-lg-3 col-md-6 col-sm-12 footer-column'>
							<div className={`${customClass} footer-widget`}>
								<h4 className='widget-title'>{title}</h4>
								<div dangerouslySetInnerHTML={{
									__html: content
								}}></div>
							</div>
						</div>
					})}
				</div>
			</div>

			<div className='footer-bottom clearfix'>
				<ul className='footer-nav pull-right'>
					{nav?.map((item, index) => {
						const { text, link } = item;

						return <li key={index}><a href={link}>{text}</a></li>
					})}
				</ul>
			</div>
		</div>

		<div className='copyright' dangerouslySetInnerHTML={{
			__html: copyright
		}}></div>
	</footer>
}
export default Footer;