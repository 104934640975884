import { render } from '@wordpress/element';

// Import Files
import './style.scss';
import Style from './style';
import Footer from './footer';

// All Instagram Feed
document.addEventListener('DOMContentLoaded', () => {
    const allFooter = document.querySelectorAll('.wp-block-b-temp-footer');
    allFooter.forEach(footer => {
        const attributes = JSON.parse(footer.dataset.attributes);

        render(<>
            <Style />
            <Footer attributes={attributes} />
        </>, footer);

        footer?.removeAttribute('data-attributes');
    });
});